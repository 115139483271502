<template>  
    <v-card class="elevation-5 mt-0" style="background-color:#c0c0c0;">
      <div style="position:absolute;top:0;left:20px;font-weight:700; text-transform: uppercase;font-size:14px;">{{ titleCard }}</div>
      <div style="position:absolute;bottom:0;left:47%;">Nivel {{ actualZoom }}</div>
      <v-card-text class="pa-5" :style="'height:' + window.height + 'px;'">
        <v-row no-gutters style="height:100%">
          <v-col>            
            <gmap-map :id="'map'+mapaNombre" :ref="'gmap'+mapaNombre"
              :center= "map.center"
              :zoom= "map.zoom"
              @idle="cambioIdle"
              :options="map.options"
              style= "width:100%; height: 100%;"
            >
              <gmap-info-window
                :options="infoOptions"
                :position="infoWindowPos"
                :opened="infoWinOpen"                
                @closeclick="infoWinOpen = false">
                <v-container
                  fluid
                >
                  <div v-show="!infoLine" style="width:100%;font-size: 1rem;font-weight: 400;">
                    <span class="mr-4">{{ infoWinTitle }}</span>
                    <v-btn title="cerrar" color="#0000b3" @click="infoWinOpen = false" class="mr-n2 mt-5" fab dark x-small absolute top right>
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </div>
                  <v-radio-group v-show="!infoLine" class="ml-1 mb-1 mt-0" hide-details dense v-model="locationType">
                    <v-radio
                    label="Origen"
                    color="#0000b3"
                    value="origen"
                    ></v-radio>
                    <v-radio
                    label="Destino"
                    color="#0000b3"
                    value="destino"
                    ></v-radio>
                  </v-radio-group>
                  
                  <v-btn v-show="!infoLine" color="#0000b3" @click="saveLocation(infoWindowPos, infoWindowId)" dark small>
                    <v-icon>mdi-save</v-icon> Guardar
                  </v-btn>

                  <div v-if="infoLine">
                    <v-btn title="cerrar" color="primary" @click="infoWinOpen = false" class="mr-n2 mt-5" fab dark x-small absolute top right>
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <div>
                      <div class="titleInfoLine">
                        <div> De {{ getNombre(infoDetail.ruta.cadena,infoDetail.position-1) }} a {{ getNombre(infoDetail.ruta.cadena,infoDetail.position)}}</div>
                        <div>{{ infoDetail.ruta.cadenaProducto[infoDetail.position-1]? infoDetail.ruta.cadenaProducto[infoDetail.position-1] : infoDetail.ruta.producto }} - {{ infoDetail.ruta.cadenaTransporte[infoDetail.position-1] }}</div>
                      </div>
                      <!---<div>{{ infoDetail.ruta.cadenaTransporte[infoDetail.position-1] }}</div>
                      <div>Flete: ${{ numberWithCommasND(Math.round((infoDetail.ruta.cadenaCostoTramo[infoDetail.position-1])*100)/100) }}</div>
                      <div>Flete Acum: ${{ numberWithCommasND(Math.round((infoDetail.ruta.cadenaAcumCosto[infoDetail.position-1])*100)/100) }} </div>
                      <div>Volumen: {{ numberWithCommasND(Math.round((infoDetail.ruta.cadenaVolumenTramo[infoDetail.position-1])*100)/100) }} Tn.</div>
                      <div>Distancia Lineal: {{ numberWithCommasND(Math.round((infoDetail.linealDistance)*100)/100)}} km</div>
                      <div>Transporte:</div> -->
                      <div>
                        <table class="mt-2">
                          <tr>
                            <th class="detailGrey">Flete</th>
                            <th class="detailGrey">Flete Acum</th>
                            <th class="detailGrey">$Prod</th>
                            <th class="detailGrey">$Integral</th>
                            <th class="detailGrey">Vol Tn</th>
                            <th class="detailGrey">Dist Km</th>
                          </tr>
                          <tr>
                            <td class="detail">{{ numberWithCommasND(Math.round((infoDetail.ruta.cadenaCostoTramo[infoDetail.position-1])*100)/100) }}</td>
                            <td class="detail">{{ numberWithCommasND(Math.round((infoDetail.ruta.cadenaAcumCosto[infoDetail.position-1])*100)/100) }}</td>
                            <td class="detail">{{ numberWithCommasND(Math.round((infoDetail.ruta.costoProduccion)*100)/100) }}</td>
                            <td class="detail">{{ numberWithCommasND(Math.round((infoDetail.ruta.cadenaAcumCosto[infoDetail.position-1]*1+infoDetail.ruta.costoProduccion*1)*100)/100) }}</td>
                            <td class="detail">{{ numberWithCommas(Math.round((infoDetail.ruta.cadenaVolumenTramo[infoDetail.position-1])*100)/100) }}</td>
                            <td class="detail">{{ numberWithCommasND(Math.round((infoDetail.linealDistance)*100)/100)}}</td>
                          </tr>                          
                        </table>
                        <template v-if="scenario=='optimorum'">
                          <div v-show="infoDetail.ruta.cadenaCostoCalculado && infoDetail.ruta.cadenaCostoCalculado[infoDetail.position-1] && infoDetail.ruta.cadenaCostoCalculado[infoDetail.position-1].value > 0">
                          Flete calculado: {{ infoDetail.ruta.cadenaCostoCalculado && infoDetail.ruta.cadenaCostoCalculado[infoDetail.position-1]?infoDetail.ruta.cadenaCostoCalculado[infoDetail.position-1].detail:'' }}
                          </div>
                        </template>
                      </div>
                      <div>
                        <table class="mt-2" v-show="!hideTableDetailTransport">
                          <tr>
                            <th class="detailGrey" rowspan="2">Transporte</th>
                            <th class="detailGrey" rowspan="2">Vehiculo</th>
                            <th class="detailGrey" colspan="2">Segmento</th>
                            <th class="detailGrey" colspan="4">Encadenado</th>
                          </tr>
                          <tr>                            
                            <th class="detailLightGrey">Vol</th>
                            <th class="detailLightGrey">$Tn</th>
                            <th class="detailLightGrey">Vol</th>                            
                            <th class="detailLightGrey">$Tn</th>
                            <th class="detailLightGrey">$PVar</th>
                            <th class="detailLightGrey">$Total</th>
                          </tr>
                          <tr v-for="item in getDetalleTransporte(infoDetail.ruta,infoDetail.position )">
                            <td class="detailGreen">{{ item.transporte }}</td>
                            <td class="detailGreen">{{ item.vehiculo }}</td>
                            <td class="detailGreen">{{ numberWithCommasND(Math.round((item.tonAbasto)*100)/100) }}</td>                            
                            <td class="detailGreen" v-if="(item.tonAbasto*1)>0">{{ numberWithCommasND(Math.round((item.fleteAbasto / item.tonAbasto)*100)/100) }}</td>
                            <td class="detailGreen">{{ numberWithCommasND(Math.round((item.tonParaVta)*100)/100) }}</td>
                            <template v-if="(item.tonAbasto*1)>0">
                              <td class="detailGreen" >{{ numberWithCommasND(Math.round((item.fleteParaVta / item.tonParaVta)*100)/100) }}</td>
                              <td class="detailGreen"> {{ numberWithCommasND(infoDetail.ruta.costoProduccion) }} </td>
                              <td class="detailGreen"> {{ numberWithCommasND(Math.round(((item.fleteParaVta / item.tonParaVta) + infoDetail.ruta.costoProduccion*1)*100)/100) }}</td>                              
                            </template>
                            <template v-else>
                              <td class="detailGreen">--</td>
                              <td class="detailGreen">--</td>
                              <td class="detailGreen">--</td>
                              <td class="detailGreen">--</td>
                            </template>
                            

                          </tr>
                        </table>                        
                      </div>
                      <div class="totalDetail mt-2" v-show="!hideTableDetailTransport">
                        $TnSeg: {{ numberWithCommasND(Math.round((infoDetail.ruta.cadenaCostoTramo[infoDetail.position-1])*100)/100) }}
                        $TonRuta: {{ numberWithCommasND(Math.round((infoDetail.ruta.cadenaCosto[infoDetail.position-1])*100)/100) }}
                        $PVar: {{ numberWithCommasND(infoDetail.ruta.costoProduccion*1) }}
                        $Total: {{ numberWithCommasND(Math.round((infoDetail.ruta.cadenaCosto[infoDetail.position-1]*1+infoDetail.ruta.costoProduccion*1)*100)/100) }}
                      </div>
                    </div>
                  </div>

                </v-container>
              </gmap-info-window>
              <gmap-marker                  
                v-for="(m, index) in plantas"
                :key="index"
                :position="m.ubicacion"
                :draggable="false"
                :label= "( actualZoom>7 )?{
                  text: m.nombre,
                  color: (styleItems[styleSelector].value.indexOf('dark')> -1 || styleItems[styleSelector].value.indexOf('night')> -1)?'#cc0000':'#0000AF',
                  fontSize:'11px',
                  fontWeight:'bold',
                  className: 'shadowLabel'
                }:null"
                :title="'Nombre: '+m.nombre+'\n'+
                        'CB: '+m.id+'\n'+
                        (m.extra.idsap?'SAP: '+m.extra.idsap+'\n':'')"
                :icon="
                  actualZoom > 13? icons.defaultIconSettings:
                  actualZoom > 5? icons.defaultIconSettings08: 
                  icons.defaultIconSettings05
                "                
                @click="setLocation($event.latLng, {title: m.nombre, id:m.id})"
                :visible="map.switchPlantas"
                >
              </gmap-marker>
              <gmap-marker                  
                v-for="(m, index) in genericos"
                :key="'g'+index"
                :position="m.ubicacion"
                :draggable="false"
                :label= "( actualZoom>11 )?{
                  text: m.nombre,
                  color: '#F02230',
                  fontSize:'11px',
                  fontWeight:'bold',
                  className: 'shadowLabel'
                }:null"
                :title="'Nombre: '+m.nombre+'\n'+
                        'CB: '+m.id+'\n'+
                        (m.extra.idsap?'SAP: '+m.extra.idsap+'\n':'')"
                :icon="icons.iconMini"
                :visible="true"
                @click="setLocation($event.latLng, {title: m.nombre, id:m.id})"
                >
              </gmap-marker>
              <gmap-marker
                v-if="Object.keys(elevationPoint).length > 0"
                :position="elevationPoint.location"
                :draggable="false"                
                :title="'Elevación: '+ numberWithCommas(elevationPoint.elevation)"
                :icon="icons.iconMini"
                :visible="true">
              </gmap-marker>
              <gmap-polyline
                v-for="(path , index) in spiders"
                :key="index + '-spider'"
                :options = "path.pathOptions"
                @click="setLocation(path.path, {title: 'Línea Polígono', id:0},true, path.info)"
                :path.sync="path.path">
              </gmap-polyline>
              <gmap-polygon
                v-for="(m, index) in poligonos"
                :key="'poly' + index"
                :paths="m.path"
                :options="m.options"
                :editable="m.editable"
                :draggable="m.draggable">
              </gmap-polygon>
              <div :id="'spider-control'+this.mapaNombre" class="map-control">
                <v-select
                  v-show="abastoNOM.length > 0 || entregasNOM.length > 0"
                  v-model="tipoSpider"
                  :items="tipoSpiderItems"
                  @change="updateSpider()"
                  solo
                  dense
                  prepend-inner-icon="mdi-map"
                  light
                  hide-details
                  style="max-width: 180px;"
                  color="secondary"
                  >
                </v-select>
              </div>
              <div :id="'controlAlcance'+this.mapaNombre">
                <div title="Detalle de la información de distancias" class="rulerDetailContainer">
                  <div
                    id="addressMapLocation"
                    v-if="Object.keys(mediciones).length > 0 && toggleShowDetail"
                    class="rulerDetail">                    
                    <h3>{{ mediciones.title }}</h3>
                    <table>
                      <tr>
                        <th class="detailGrey" colspan="2">Distancia</th>
                        <th class="detailGrey" rowspan="2">Tiempo</th>
                        <th class="detailGrey" rowspan="2">Velocidad</th>
                        <th class="detailGrey" colspan="2">Costo</th>
                      </tr>
                      <tr>
                        <th class="detailLightGrey">Calle</th>
                        <th class="detailLightGrey">Lineal</th>
                        <!-- <th class="detailLightGrey">Prom</th> -->
                        <!-- <th class="detailLightGrey">Tráfico</th> -->
                        <th class="detailLightGrey">$Prom</th>
                        <th class="detailLightGrey">$TnKm</th>
                      </tr>
                      <tr>
                        <td class="detail">{{ mediciones.streetDistance }}</td>
                        <td class="detail">{{ mediciones.linealDistance }}</td>
                        <!-- <td class="detail">{{ getTimeLabel(mediciones.averageTime) }}</td> -->
                        <td class="detail">{{ getTimeLabel(mediciones.trafficTime) }}</td>
                        <td class="detail">{{ mediciones.speed }}</td>
                        <td class="detail">{{ mediciones.costo }}</td>
                        <td class="detail">{{ mediciones.costoTnKm}}</td>
                      </tr>
                    </table>
                    <!-- <div>{{ lblStreetDistance }}<div>{{ mediciones.streetDistance }}</div></div>
                    <div>{{ lblLinealDistance }}<div>{{ mediciones.linealDistance }}</div></div>
                    <div>{{ lblAverageTime }}<div>{{ getTimeLabel(mediciones.averageTime) }} </div></div>
                    <div>{{ lblTimeWTraffic }}<div>{{ getTimeLabel(mediciones.trafficTime) }} </div></div>
                    <div>Velocidad<div>{{ mediciones.speed }} </div></div>
                    <div>Costo Seg Prom<div>{{ mediciones.costo }} </div><div>{{ mediciones.costoTnKm}} </div></div>  -->                   
                  </div>                  
                  <v-icon                    
                    dark
                    :color="this.bRegla ? '#0000b3' : '#555'"
                    :title="titleMediciones"
                    large
                    dense
                    @click="muestraRegla()"
                    class="mt-2">
                    mdi-map-marker-distance
                  </v-icon>                  
                  <!-- <v-icon
                  dark
                  color="#0000fb"
                  v-if="toggleShowDetail"
                  :title="lblHideRulerDetail"
                  @click="toggleShowDetail = !toggleShowDetail"
                  class="ml-1 mt-2"

                >
                  mdi-message-bulleted-off
                </v-icon> -->
                <v-icon
                  dark
                  :color="this.toggleShowDetail ? '#0000b3' : '#555'"
                  :title="lblShowRulerDetail"
                  @click="toggleShowDetail = !toggleShowDetail"
                  class="ml-1 mt-2"
                >
                  mdi-message-bulleted
                </v-icon>
                <v-icon                    
                    dark
                    :color="this.showChart ? '#0000b3' : '#555'"
                    :title="'Muestra Elevación'"
                    large
                    dense
                    @click="toggleChart()">
                    mdi-elevation-rise                 
                  </v-icon>
                </div>
                <!-- <v-icon dark color="#555" @click="llenaCuadrantes()" title="Llena cuadrantes"> mdi-grid</v-icon> -->
              </div>
              <div :id="'controlSearch'+this.mapaNombre" class="map-control-complex" :style="'margin-left:0px;background-color: '+(sizeSearch<1?'#fff;':'transparent;')">
                <!-- <v-btn
                  v-if="sizeSearch == 0"
                  dark
                  color="white"
                  fab
                  small
                  @click="sizeSearch = 300"
                  title="Find location"
                  class="mt-1"
                > -->
                  <v-icon v-if="sizeSearch == 0" @click="sizeSearch = 300" title="Encuentra ubicación" color="#555" >
                    mdi-map-search
                  </v-icon>
                <!-- </v-btn> -->
                <div v-show="sizeSearch>0" :style="'box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;width:' + sizeSearch + 'px;background:#fff;border-radius: 0px 10px 10px;'">
                  <v-text-field
                    :id="'pac-input'+this.mapaNombre"
                    style="width:95%;background-color:transparent;"
                    class="ma-0 pa-0 pl-2 pb-1 mb-n6"
                  ></v-text-field>
                </div>
                <v-icon
                  v-if="sizeSearch > 0"
                  @click="clearMarkersSearchBox()"
                  title="limpia búsqueda"
                  color="primary"
                  dark
                  class="ml-1"
                >
                  mdi-delete
                </v-icon>
                <!-- <v-icon
                  v-if="sizeSearch > 0"
                  @click="sizeSearch = 120"
                  title="collapse section"
                  color="primary"
                  dark
                  class="ml-1"
                >
                  mdi-arrow-left-bold-circle
                </v-icon> -->
                <!-- <v-icon
                  v-if="sizeSearch > 0"
                  @click="sizeSearch = 300"
                  title="expand section"
                  color="primary"
                  dark
                  class="ml-1"
                >
                  mdi-arrow-right-bold-circle
                </v-icon> -->
                <v-icon
                  v-if="sizeSearch > 0"
                  @click="sizeSearch = 0"
                  color="#0000b3"
                  dark
                  class="ml-1"
                  title="regresar"
                >
                  mdi-arrow-left-bold
                </v-icon>
              </div>
              
            </gmap-map>
            <div :id="'elevation_chart'+this.mapaNombre" class="elevationChart" v-show="showChart" width="500px">
              <!-- <div @click="refrescaChart+=1;showChart=!showChart">
                Cerrar
              </div> -->
              <table>
                <tr>                  
                  <td style="min-width:400px;">
                    <bar-chart v-show="showChart" :styles="myStyles"  ref="beneChart" :key="'Chart-'+refrescaChart" :chart-data="chartData" :options="chartOptions" ></bar-chart>
                  </td>
                  </tr>
                  <tr>
                    <td>
                      <template v-if="resultElevation.length>0">
                        <div style="font-size:0.9rem;">
                          Inicial: {{ this.numberWithCommas(resultElevation[0].elevation) }} m
                          Final: {{ this.numberWithCommas(resultElevation[255].elevation) }} m
                          Dif: {{ this.numberWithCommas(resultElevation[255].elevation - resultElevation[0].elevation)}} m
                        </div>
                      </template>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <template v-if="resultElevation.length>0">
                        <div style="font-size:0.9rem;">
                          Pendiente Acum: {{ this.numberWithCommas(acumSlope)}} m 
                          (%): {{ this.numberWithCommas((acumSlope * 100) / (mediciones.streetDistance.substring(0, mediciones.streetDistance.length - 3)*1000))}}
                        </div>
                      </template>
                    </td>
                  </tr>
              </table>
            </div>
            <div :id="'style-selector-control'+this.mapaNombre" class="map-control-complex" style="background-color: #fff;">
                <v-menu
                  offset-y
                >
                  <template v-slot:activator="{ attrs, on }">
                                        
                    <v-icon
                      v-bind="attrs"
                      v-on="on"                      
                      :color="'#555'"
                    >
                    mdi-palette</v-icon>
                  </template>

                  <v-list >
                    <v-list-item-group
                      v-model="styleSelector"
                      color="primary"
                    >
                      <v-list-item
                        v-for="item in styleItems"
                        :key="item.value"
                        link
                      >
                        <v-list-item-title v-text="item.text"></v-list-item-title>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  
</template>


<script>
import { gmapApi } from 'vue2-google-maps'
import BarChart from '@/components/Charts/Bar' 
import { EventBus } from "../utils/eventBus.js";

export default {
  name: 'mapViewer',
  components: {BarChart},
  data: () => ({
  styleSelector:0,
  styleItems:[
    {value:"default", text:"Estándar"},
    {value:"silver", text:"Plateado"},
    {value:"dark", text:"Oscuro"},
    {value:"night", text:"Noche"},
    {value:"retro", text:"Retro"},
    {value:"hiding", text:"Sin Detalle"}],
  mapStyles:{
    default: [],
    silver: [
        {
          elementType: "geometry",
          stylers: [{ color: "#f5f5f5" }],
        },
        {
          elementType: "labels.icon",
          stylers: [{ visibility: "off" }],
        },
        {
          elementType: "labels.text.fill",
          stylers: [{ color: "#616161" }],
        },
        {
          elementType: "labels.text.stroke",
          stylers: [{ color: "#f5f5f5" }],
        },
        {
          featureType: "administrative.land_parcel",
          elementType: "labels.text.fill",
          stylers: [{ color: "#bdbdbd" }],
        },
        {
          featureType: "poi",
          elementType: "geometry",
          stylers: [{ color: "#eeeeee" }],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [{ color: "#757575" }],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [{ color: "#e5e5e5" }],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [{ color: "#9e9e9e" }],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [{ color: "#ffffff" }],
        },
        {
          featureType: "road.arterial",
          elementType: "labels.text.fill",
          stylers: [{ color: "#757575" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [{ color: "#dadada" }],
        },
        {
          featureType: "road.highway",
          elementType: "labels.text.fill",
          stylers: [{ color: "#616161" }],
        },
        {
          featureType: "road.local",
          elementType: "labels.text.fill",
          stylers: [{ color: "#9e9e9e" }],
        },
        {
          featureType: "transit.line",
          elementType: "geometry",
          stylers: [{ color: "#e5e5e5" }],
        },
        {
          featureType: "transit.station",
          elementType: "geometry",
          stylers: [{ color: "#eeeeee" }],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [{ color: "#c9c9c9" }],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [{ color: "#9e9e9e" }],
        },
      ],
      dark: [
          {
              "featureType": "all",
              "elementType": "all",
              "stylers": [
                  {
                      "visibility": "on"
                  }
              ]
          },
          {
              "featureType": "all",
              "elementType": "geometry",
              "stylers": [
                  {
                      "visibility": "on"
                  }
              ]
          },
          {
              "featureType": "all",
              "elementType": "labels.text.fill",
              "stylers": [
                  {
                      "saturation": 36
                  },
                  {
                      "color": "#000000"
                  },
                  {
                      "lightness": 40
                  }
              ]
          },
          {
              "featureType": "all",
              "elementType": "labels.text.stroke",
              "stylers": [
                  {
                      "visibility": "on"
                  },
                  {
                      "color": "#000000"
                  },
                  {
                      "lightness": 16
                  }
              ]
          },
          {
              "featureType": "all",
              "elementType": "labels.icon",
              "stylers": [
                  {
                      "visibility": "off"
                  }
              ]
          },
          {
              "featureType": "administrative",
              "elementType": "geometry.stroke",
              "stylers": [
                  {
                      "color": "#000000"
                  },
                  {
                      "lightness": 17
                  },
                  {
                      "weight": 1.2
                  }
              ]
          },
          {
              "featureType": "administrative.country",
              "elementType": "geometry",
              "stylers": [
                  {
                      "color": "#868686"
                  },
                  {
                      "visibility": "off"
                  }
              ]
          },
          {
              "featureType": "administrative.country",
              "elementType": "geometry.stroke",
              "stylers": [
                  {
                      "visibility": "on"
                  }
              ]
          },
          {
              "featureType": "administrative.country",
              "elementType": "labels.text.fill",
              "stylers": [
                  {
                      "color": "#d2d2d2"
                  }
              ]
          },
          {
              "featureType": "administrative.province",
              "elementType": "geometry",
              "stylers": [
                  {
                      "color": "#676767"
                  },
                  {
                      "visibility": "on"
                  }
              ]
          },
          {
              "featureType": "administrative.locality",
              "elementType": "labels.text.fill",
              "stylers": [
                  {
                      "color": "#848484"
                  }
              ]
          },
          {
              "featureType": "landscape",
              "elementType": "geometry",
              "stylers": [
                  {
                      "color": "#000000"
                  },
                  {
                      "lightness": 20
                  }
              ]
          },
          {
              "featureType": "landscape",
              "elementType": "geometry.fill",
              "stylers": [
                  {
                      "color": "#212121"
                  },
                  {
                      "visibility": "on"
                  }
              ]
          },
          {
              "featureType": "landscape.man_made",
              "elementType": "geometry.fill",
              "stylers": [
                  {
                      "visibility": "on"
                  },
                  {
                      "color": "#212121"
                  }
              ]
          },
          {
              "featureType": "landscape.natural",
              "elementType": "geometry.fill",
              "stylers": [
                  {
                      "visibility": "on"
                  },
                  {
                      "color": "#212121"
                  }
              ]
          },
          {
              "featureType": "landscape.natural.landcover",
              "elementType": "geometry.fill",
              "stylers": [
                  {
                      "color": "#212121"
                  },
                  {
                      "visibility": "on"
                  }
              ]
          },
          {
              "featureType": "landscape.natural.terrain",
              "elementType": "geometry.fill",
              "stylers": [
                  {
                      "color": "#212121"
                  },
                  {
                      "visibility": "on"
                  }
              ]
          },
          {
              "featureType": "poi",
              "elementType": "geometry",
              "stylers": [
                  {
                      "lightness": 21
                  },
                  {
                      "color": "#212121"
                  }
              ]
          },
          {
              "featureType": "poi.park",
              "elementType": "geometry",
              "stylers": [
                  {
                      "color": "#181818"
                  }
              ]
          },
          {
              "featureType": "road",
              "elementType": "geometry",
              "stylers": [
                  {
                      "visibility": "simplified"
                  }
              ]
          },
          {
              "featureType": "road.highway",
              "elementType": "geometry",
              "stylers": [
                  {
                      "visibility": "simplified"
                  },
                  {
                      "color": "#3c3c3c"
                  }
              ]
          },
          {
              "featureType": "road.highway",
              "elementType": "geometry.stroke",
              "stylers": [
                  {
                      "lightness": 29
                  },
                  {
                      "weight": 0.2
                  }
              ]
          },
          {
              "featureType": "road.highway.controlled_access",
              "elementType": "geometry",
              "stylers": [
                  {
                      "color": "#4e4e4e"
                  },
                  {
                      "visibility": "simplified"
                  },
                  {
                      "lightness": "-20"
                  }
              ]
          },
          {
              "featureType": "road.arterial",
              "elementType": "geometry",
              "stylers": [
                  {
                      "visibility": "simplified"
                  },
                  {
                      "color": "#373737"
                  }
              ]
          },
          {
              "featureType": "road.arterial",
              "elementType": "geometry.fill",
              "stylers": [
                  {
                      "visibility": "on"
                  }
              ]
          },
          {
              "featureType": "road.local",
              "elementType": "geometry",
              "stylers": [
                  {
                      "lightness": 16
                  },
                  {
                      "color": "#313131"
                  },
                  {
                      "visibility": "simplified"
                  }
              ]
          },
          {
              "featureType": "transit",
              "elementType": "geometry",
              "stylers": [
                  {
                      "color": "#212121"
                  },
                  {
                      "lightness": 19
                  },
                  {
                      "visibility": "off"
                  }
              ]
          },
          {
              "featureType": "water",
              "elementType": "geometry",
              "stylers": [
                  {
                      "color": "#000000"
                  },
                  {
                      "visibility": "on"
                  }
              ]
          }
      ],
      night: [
        { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
        { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
        { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
        {
          featureType: "administrative.locality",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [{ color: "#263c3f" }],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [{ color: "#6b9a76" }],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [{ color: "#38414e" }],
        },
        {
          featureType: "road",
          elementType: "geometry.stroke",
          stylers: [{ color: "#212a37" }],
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [{ color: "#9ca5b3" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [{ color: "#746855" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [{ color: "#1f2835" }],
        },
        {
          featureType: "road.highway",
          elementType: "labels.text.fill",
          stylers: [{ color: "#f3d19c" }],
        },
        {
          featureType: "transit",
          elementType: "geometry",
          stylers: [{ color: "#2f3948" }],
        },
        {
          featureType: "transit.station",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [{ color: "#17263c" }],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [{ color: "#515c6d" }],
        },
        {
          featureType: "water",
          elementType: "labels.text.stroke",
          stylers: [{ color: "#17263c" }],
        },
      ],
      retro: [
        { elementType: "geometry", stylers: [{ color: "#ebe3cd" }] },
        { elementType: "labels.text.fill", stylers: [{ color: "#523735" }] },
        { elementType: "labels.text.stroke", stylers: [{ color: "#f5f1e6" }] },
        {
          featureType: "administrative",
          elementType: "geometry.stroke",
          stylers: [{ color: "#c9b2a6" }],
        },
        {
          featureType: "administrative.land_parcel",
          elementType: "geometry.stroke",
          stylers: [{ color: "#dcd2be" }],
        },
        {
          featureType: "administrative.land_parcel",
          elementType: "labels.text.fill",
          stylers: [{ color: "#ae9e90" }],
        },
        {
          featureType: "landscape.natural",
          elementType: "geometry",
          stylers: [{ color: "#dfd2ae" }],
        },
        {
          featureType: "poi",
          elementType: "geometry",
          stylers: [{ color: "#dfd2ae" }],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [{ color: "#93817c" }],
        },
        {
          featureType: "poi.park",
          elementType: "geometry.fill",
          stylers: [{ color: "#a5b076" }],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [{ color: "#447530" }],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [{ color: "#f5f1e6" }],
        },
        {
          featureType: "road.arterial",
          elementType: "geometry",
          stylers: [{ color: "#fdfcf8" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [{ color: "#f8c967" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [{ color: "#e9bc62" }],
        },
        {
          featureType: "road.highway.controlled_access",
          elementType: "geometry",
          stylers: [{ color: "#e98d58" }],
        },
        {
          featureType: "road.highway.controlled_access",
          elementType: "geometry.stroke",
          stylers: [{ color: "#db8555" }],
        },
        {
          featureType: "road.local",
          elementType: "labels.text.fill",
          stylers: [{ color: "#806b63" }],
        },
        {
          featureType: "transit.line",
          elementType: "geometry",
          stylers: [{ color: "#dfd2ae" }],
        },
        {
          featureType: "transit.line",
          elementType: "labels.text.fill",
          stylers: [{ color: "#8f7d77" }],
        },
        {
          featureType: "transit.line",
          elementType: "labels.text.stroke",
          stylers: [{ color: "#ebe3cd" }],
        },
        {
          featureType: "transit.station",
          elementType: "geometry",
          stylers: [{ color: "#dfd2ae" }],
        },
        {
          featureType: "water",
          elementType: "geometry.fill",
          stylers: [{ color: "#b9d3c2" }],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [{ color: "#92998d" }],
        },
      ],
      hiding: [
        {
          featureType: "poi.business",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "transit",
          elementType: "labels.icon",
          stylers: [{ visibility: "off" }],
        },
      ],
    },
    showChart: false,
    resultElevation:[],
    acumSlope:0,
    elevationPoint:{},
    refrescaChart: 1,    
    myStyles: {
      height: '200px',
      width: '390px',
      position: 'relative',
    },
    chartData: {
      labels: [],
      datasets: [{
        label: 'Elevation (m)',
        data: [],
        backgroundColor: 'rgba(0, 74, 127, 1)',
      }]
    },
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend:{
        display: false
      },
      scales: {
        xAxes: [{
          display: false,
          barPercentage: 1.3,          
        }],
        yAxes: [{
          ticks: {
            beginAtZero:true,
            fontSize: 14
          }
        }]
      },
      title: {
        text: 'Elevación',
        position: 'top',
        display: true,
        padding: 1,
        fontSize: 16
      },
      tooltips:{
        titleFontSize: 14,
        titleFontFamily:"'Quicksand', 'sans-serif'",
        bodyFontSize: 14,
        bodyFontFamily:"'Quicksand', 'sans-serif'",
      }
    },
    colors:['#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080', '#ffff00', '#000000'],
    locationType: 'origen',
    statusText: '',
    infoWinTitle: '',
    infoWinOpen: false,
    infoLine: false,
    infoDetail: {},
    infoOptions: {
      pixelOffset: {
        width: 0,
        height: -15,
      },
      headerDisabled: true,
      maxWidth: 480,
      disableAutoPan: true,
    },
    infoWindowPos: null,
    infoWindowId: '',
    lblAverageTime:'Tiempo promedio',
    lblTimeWTraffic:'Tiempo promedio con tráfico',
    lblStreetDistance:'Distancia por la calle',
    lblLinealDistance:'Distancia lineal',
    mediciones: {}, //Mediciones encontradas con la regla
    lblShowRulerDetail:'Muestra detalle de regla',
    lblHideRulerDetail:'Oculta detalle de regla',
    lblShowRuler:'Muestra regla',
    lblHideRuler:'Oculta regla',
    lblRuler:'Regla',
    lblMediciones:'Esfuerzo Logísitco',
    titleRegla: "Muestra regla",
    titleMediciones: "Muestra esfuerzo logístico",
    toggleShowDetail: true,
    directionsService: "",
    directionsDisplay: "",
    elevationService: "",
    bRegla: 0,
    originRule:{},//{lat: 19.43261, lng: -99.13321},
    destinationRule:{},//{lat: 19.43261, lng: -99.13321}
    originId:{},//{lat: 19.43261, lng: -99.13321},
    destinationId:{},//{lat: 19.43261, lng: -99.13321}
    waypointsRule:[],//[{lat: 19.43261, lng: -99.13321}]
    time: new Date(),
    genericos:[],
    tipoSpider:'sin',
    tipoSpiderItems:[
      { text:'Sin spider', value:'sin' },
      { text:'Abasto', value:'abasto' },
      { text:'Entregas', value:'entregas' }      
    ],
    ajusteVentana: 75,
    window: { width: 0, height: 0 },
    map: {
      center: { lat: 19.43261, lng: -99.13321 },
      zoom: 11,
    },
    icons: {
      defaultIconSettings:'',
      defaultIconSettings05:'',
      defaultIconSettings08:'',
      iconMini:'',
      iconMiniRed:''
    },
    actualZoom:'',
    spiders:[],
    poligonos:[],
    searchBox: "",
    sizeSearch: 0,
    markersSearchBox: [],
    elevation:[]
  }),
  created() {
    setInterval(() => (this.time = new Date()), 1000 * 60)
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    // removing eventBus listener
    EventBus.$off('send-index')
  },
  mounted(){
    EventBus.$on('send-index', (e) => {
      this.elevationPoint= JSON.parse(JSON.stringify(this.resultElevation[e.x]))
    })
    
    
    this.$refs['gmap'+this.mapaNombre].$mapPromise.then((map) => {
      
      this.map.options= {
        scaleControl: true, 
        mapTypeControl: true,
        mapTypeId: 'roadmap', 
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          mapTypeIds: ['roadmap', 'terrain','satellite','hybrid'],
          position: google.maps.ControlPosition.TOP_RIGHT,
        }, 
        fullscreenControl:true,
        streetViewControl:true,
        streetViewControlOptions: {
          position: google.maps.ControlPosition.TOP_LEFT
        },
        zoomControl : true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_TOP
        },
        backgroundColor:"#01579B",
        controlSize:35,
        styles: this.mapStyles[this.styleItems[this.styleSelector].value]
      }

      const styleControl = document.getElementById("style-selector-control"+this.mapaNombre);
      styleControl.index = 1; // Esto es importante sino arroja error.
      map.controls[google.maps.ControlPosition.TOP_LEFT].push(styleControl);
      
      const myControlChart = document.getElementById('elevation_chart'+this.mapaNombre);
      myControlChart.index = 1; // Esto es importante sino arroja error.
      map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(myControlChart);

      const myControlMI = document.getElementById('spider-control'+this.mapaNombre);
      myControlMI.index = 1; // Esto es importante sino arroja error.
      map.controls[google.maps.ControlPosition.TOP_LEFT].push(myControlMI);

      const myControl3 = document.getElementById("controlAlcance"+this.mapaNombre);
      myControl3.index = -1; // Esto es importante sino arroja error.
      map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(myControl3);

      const myControlPI = document.getElementById("controlSearch"+this.mapaNombre);
      myControlPI.index = 1; // Esto es importante sino arroja error.
      this.searchBox = new google.maps.places.SearchBox(
        document.getElementById("pac-input"+this.mapaNombre)
      );
      map.controls[google.maps.ControlPosition.TOP_LEFT].push(myControlPI);

      // Listen for the event fired when the user selects a prediction and retrieve
      // more details for that place.
      this.searchBox.addListener("places_changed", () => {
        const places = this.searchBox.getPlaces();

        if (places.length == 0) {
          return;
        }

        // Clear out the old markers.
        this.markersSearchBox.forEach((marker) => {
          marker.setMap(null);
        });
        this.markersSearchBox = [];

        // For each place, get the icon, name and location.
        const bounds = new google.maps.LatLngBounds();

        places.forEach((place) => {
          if (!place.geometry || !place.geometry.location) {
            console.log("Returned place contains no geometry");
            return;
          }

          const icon = {
            url: place.icon,
            size: new google.maps.Size(71, 71),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(17, 34),
            scaledSize: new google.maps.Size(25, 25),
          };

          // Create a marker for each place.
          this.markersSearchBox.push(
            new google.maps.Marker({
              map,
              icon,
              title: place.name+'\n'+place.geometry.location,
              position: place.geometry.location,              
            })
          );

          if (place.geometry.viewport) {
            // Only geocodes have viewport.
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }
        });
        map.fitBounds(bounds);
      });

      this.directionsService = new google.maps.DirectionsService();
      
      this.directionsDisplay = new google.maps.DirectionsRenderer({
        draggable: true,
        preserveViewport: true,
        //map: map,
        //panel: this.panelDirecciones
      });
      this.directionsDisplay.addListener("directions_changed", () => {
        this.computeTotalDistance(this.directionsDisplay.getDirections());
      });

      this.elevationService = new google.maps.ElevationService();
    })

    this.$gmapApiPromiseLazy().then(() => {

      this.icons.defaultIconSettings = {
        url: require("@/assets/tagcmx1r.png"),
        size: new google.maps.Size(33, 33),
        labelOrigin: new google.maps.Point(15, 33),
      };
      this.icons.defaultIconSettings05 = {
        url: require("@/assets/tagcmx5r.png"),
        size: new google.maps.Size(15, 15),
        labelOrigin: new google.maps.Point(8, 15),
      };
      this.icons.defaultIconSettings08 = {
        url: require("@/assets/tagcmx8r.png"),
        size: new google.maps.Size(28, 28),
        labelOrigin: new google.maps.Point(13, 28),
      };
      this.icons.iconMini = {
        url: require('@/assets/plantmini.png'),
        size: new google.maps.Size(12,12),
        labelOrigin: new google.maps.Point(6, 16)
      }
      this.icons.iconMiniRed = {
        url: require('@/assets/plantminir.png'),
        size: new google.maps.Size(12,12),
        labelOrigin: new google.maps.Point(6, 16)
      }
    });
    
  },
  computed: {
    google: gmapApi
  },
  watch:{
    /* selectedRoute(newVal){
      console.log("Ruta seleccionada",newVal);
    }, */
    styleSelector(){
      this.setMapStyle()
    },
    rutas(newVal, oldVal){

      if(newVal.length<1){
        this.genericos =[]
        this.spiders=[]
        return;
      }

      this.genericos = []
      let bounds = new google.maps.LatLngBounds();
      let marcadorAnterior= {}
      this.spiders= []
      let unicos= {}
      let item=null
      let estatusRutas= {}
      let linealDistance=''

      setTimeout(() => {
        newVal.forEach(ruta =>{          
          if (ruta.puntos.some(e => (e.ubicacion.lat === 0 || e.ubicacion.lng === 0 ))) {
            estatusRutas[ruta.renglon]=1
          }
        })
        
        newVal.forEach(ruta =>{
          marcadorAnterior={} 
          
          for(let i=0;i< ruta.puntos.length;i++){
            item = ruta.puntos[i]

            if(estatusRutas[ruta.renglon] > 0){
              continue;
            }

            if(!item.nombre.startsWith("MX-PTA.") && !item.nombre.startsWith("Pta.")){
              if(!unicos[item.nombre]){
                this.genericos.push(item)
                unicos[item.nombre]= 1
              }
            }            

            let marker = new google.maps.Marker({
              position: {
                lat: item.ubicacion.lat,
                lng: item.ubicacion.lng
              }
            });
            
            /* Set marker to bounds */
            bounds.extend(marker.getPosition());            

            if(Object.keys(marcadorAnterior).length > 0 ){
              let marcadorAnt = new google.maps.Marker({
                position: {
                  lat: marcadorAnterior.position.lat,
                  lng: marcadorAnterior.position.lng
                }
              });

              /* console.log('Ruta'+i+': ',JSON.stringify(ruta.cadenaTransporte),'hola: '+ruta.cadenaTransporte[i-1] )
              const findVehicle = this.findByMatchingProperties(this.vehicle, {
                idOrigen:  ruta.idOrigen,
                idDestino: ruta.idDestino,
                producto: ruta.producto
              })
              console.log("vehiculos encontrados:",JSON.stringify(findVehicle)) */
              let selectedStyle = this.styleItems[this.styleSelector].value
              let colorSpider = ruta.cadenaTransporte[i-1].indexOf('Auto')> -1 && ruta.cadenaTransporte[i-1].indexOf('FFCC')> -1? "#267F00":
                                ruta.cadenaTransporte[i-1].indexOf('Auto')> -1? "#0000ff":
                                ruta.cadenaTransporte[i-1].indexOf('Barco')> -1? "#ff0000":
                                ruta.cadenaTransporte[i-1].indexOf('FFCC')> -1? (selectedStyle.indexOf('dark')> -1 || selectedStyle.indexOf('night')> -1)?"#c0c0c0":"#000": (selectedStyle.indexOf('dark')> -1 || selectedStyle.indexOf('night')> -1)?"#c0c0c0":"#000"
              
              this.spiders.push({
                info:{ position:i, item, ruta, linealDistance: this.haversine_distance(marcadorAnt, marker) },
                path:[
                  {lat: marcadorAnterior.position.lat*1, lng: marcadorAnterior.position.lng*1 },
                  {lat: item.ubicacion.lat, lng: item.ubicacion.lng }
                ],
                pathOptions:{  //autoyffcc:green auto:blue  barco:red  ffcc:black otros:black
                  strokeColor: colorSpider,
                  strokeWeight: 8,
                  editable: false,
                  strokeOpacity: 0,
                  icons: [{
                    icon: {
                      path: ruta.cadenaTransporte[i-1].indexOf('Auto')> -1? 'M 0,-1 0,1' : ruta.cadenaTransporte[i-1].indexOf('Barco')> -1? 'M 0,-1 0,1'  : ruta.cadenaTransporte[i-1].indexOf('FFCC')> -1?'M 1,0 H -1': 'M 0,-1 0,1',
                      strokeOpacity: 1,
                      scale: 3
                    },
                    offset: '0',
                    repeat: ruta.cadenaTransporte[i-1].indexOf('Auto')> -1? '15px' : ruta.cadenaTransporte[i-1].indexOf('Barco')> -1? '20px'  : ruta.cadenaTransporte[i-1].indexOf('FFCC')> -1?'5px': '15px'
                  }, {
                    icon: {
                      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                      offset: '100%',
                      fillColor: colorSpider,
                      fillOpacity: 1,
                      strokeOpacity: 1,
                      scale: 3
                    }
                  }],
                }
              })
            }
            marcadorAnterior= JSON.parse(JSON.stringify(marker))
          }
        })
        this.$refs['gmap'+this.mapaNombre].$mapObject.fitBounds(bounds); //auto-zoom
        this.$refs['gmap'+this.mapaNombre].$mapObject.panToBounds(bounds); //auto-center
      }, "500")
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  props:{
    plantas: Array,
    abastoNOM: Array,
    entregasNOM: Array,
    poligonosNOM: Array,
    rutas: Array,
    selectedRoute: Object,
    cb: Object,
    vehicle: Array,
    ytdRoutes: Array,
    mapaNombre: String,
    titleCard: String,
    hideTableDetailTransport:Boolean,
    scenario: String
  },
  methods:{
    toggleChart(){
      this.showChart=!this.showChart
      if(!this.showChart){
        this.elevationPoint=[]
      }
    },
    setMapStyle(){
      this.map.options= {
        scaleControl: true, 
        mapTypeControl: true,
        mapTypeId: 'roadmap', 
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          mapTypeIds: ['roadmap', 'terrain','satellite','hybrid'],
          position: google.maps.ControlPosition.TOP_RIGHT,
        }, 
        fullscreenControl:true,
        streetViewControl:true,
        streetViewControlOptions: {
          position: google.maps.ControlPosition.TOP_LEFT
        },
        zoomControl : true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_TOP
        },
        backgroundColor:"#01579B",
        controlSize:30,
        styles: this.mapStyles[this.styleItems[this.styleSelector].value]
      }
    },
    getDetalleTransporte(ruta, posicion){
      let findings = []
      const findVehicle = this.findByMatchingProperties(this.vehicle, {
        idOrigen:  ruta.cadena[posicion-1],
        idDestino: ruta.cadena[posicion],
        producto: ruta.cadenaProducto[posicion-1]? ruta.cadenaProducto[posicion-1]: ruta.producto
      })
      //console.log(findVehicle)
      return findVehicle
    },
    findByMatchingProperties(set, properties) {
      return set.filter(function (entry) {
          return Object.keys(properties).every(function (key) {
              return entry[key] === properties[key];
          });
      });
    },
    getNombre(obj, indice){
      let centro = obj[indice]
      let descripcion = ''
      if(centro){
        descripcion = centro
        if(this.cb[centro]!== undefined){
          descripcion = this.cb[centro].descripcion
        }
      }
      return descripcion
    },
    haversine_distance(mk1, mk2) {
      
      var R = 6371.0710 // 3958.8 Radius of the Earth in miles
      var rlat1 = mk1.position.lat() * (Math.PI/180); // Convert degrees to radians
      var rlat2 = mk2.position.lat() * (Math.PI/180); // Convert degrees to radians
      
      var difflat = rlat2-rlat1; // Radian difference (latitudes)
      var difflon = (mk2.position.lng()-mk1.position.lng()) * (Math.PI/180); // Radian difference (longitudes)

      var d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat/2)*Math.sin(difflat/2)+Math.cos(rlat1)*Math.cos(rlat2)*Math.sin(difflon/2)*Math.sin(difflon/2)));
      return d*1.25;
    },
    llenaCuadrantes(){
      
      this.$gmapApiPromiseLazy().then(() => {
        //console.log(this)
        var bounds = this.$refs['gmap'+this.mapaNombre].$mapObject.getBounds();
        var mapne = bounds.getNorthEast(); // LatLng of the north-east corner
        var mapsw = bounds.getSouthWest(); // LatLng of the south-west corder
        //console.log('Bounds object',{ x1: mapsw.lng(),y1: mapsw.lat(),x2: mapne.lng(),y2: mapne.lat() })
        
        /*if (this.actualZoom >= 11) {
          var plazaA = sessionStorage.getItem("plaza");
          this["cuadrante/fetchCuadrantes"]({
            x1: mapsw.lng(),
            y1: mapsw.lat(),
            x2: mapne.lng(),
            y2: mapne.lat(),
            country: this.getCountryState[plazaA],
          });
          this.dummy = false;
          //console.log({ x1: mapsw.lng(),y1: mapsw.lat(),x2: mapne.lng(),y2: mapne.lat() })
        } else {
          this.dummy = true;
          this.colorMsg = "error";
          this.message =
            "Zoom too wide to load quadrants, please focus on a specific area";
          this["cuadrante/clearCuadrantes"]();
        } */
      });
    },
    getTimeLabel(minutes){      
      let hours   = Math.floor(minutes / 60);
      let min = Math.floor(minutes % 60);
      
      if (hours <= 0) {
        return "00h " + min + " min";
        }
      return hours + "h " + min + " min";
    },
    muestraRegla: function() {
      this.dummy = false;
      if (this.bRegla == 0) {
        this.calculateAndDisplayRoute(this.time);
        this.bRegla = 1;
        this.titleBtnRegla = this.lblHideRuler;
      } else {
        this.directionsDisplay.setMap(null);
        this.bRegla = 0;
        this.titleBtnRegla = this.lblShowRuler;        
        this.mediciones = {}
        this.originRule= {}
        this.destinationRule= {}
        this.originId= ''
        this.destinationId= ''
        this.elevationPoint=[]
        this.resultElevation= []
        this.chartData.labels = []
        this.chartData.datasets[0].data = []
        this.refrescaChart+=1;
        this.showChart=false;
      }
    },
    numberWithCommas(n) {
      var parts = n.toString().split(".");
      return (
        parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
        (parts[1] ? "." + parts[1].substr(0, 2) : "")
      );
    },
    numberWithCommasND(n) {
      var parts = n.toString().split(".");
      return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    displayLocationElevation(path, elevator) {

      elevator.getElevationAlongPath({ path: path, samples: 256,})
      /* .then(({ results }) =>{
        this.elevation = [...results]
      }) */
      .then(this.plotElevation)
      .catch((e) => {
        const chartDiv = document.getElementById("elevation_chart"+this.mapaNombre);
        // Show the error code inside the chartDiv.
        //chartDiv.innerHTML = "Cannot show elevation: request failed because " + e;        
      });
    },
    calculateAndDisplayRoute(time) {
      //console.log("DISPLAY_ROUTE")
      //console.log(this)
      var self = this;
      if (
        (Object.entries(self.originRule).length === 0 &&
          self.originRule.constructor === Object) ||
        (Object.entries(self.destinationRule).length === 0 &&
          self.destinationRule.constructor === Object)
      ) {
        this.dummy = true;
        this.colorMsg = "error";
        this.message =
          "Hay que definir origen y destino para poder usar el servicio";
        return;
      }
      //console.log(self.$refs.mstart.$markerObject.position)
      //console.log(self.$refs.mend.$markerObject.position)
      //console.log(time)
      
      /* this.displayLocationElevation([
        { lat: self.originRule.lat(), lng: self.originRule.lng()},{lat: self.destinationRule.lat(), lng: self.destinationRule.lng()}],this.elevationService); */

      self.directionsService.route(
        {
          origin: self.originRule,
          destination: self.destinationRule,
          waypoints: self.waypointsRule,
          travelMode: "DRIVING",
          drivingOptions: {
            departureTime: time, // for the time N milliseconds from now.
            trafficModel: "bestguess", //'pessimistic' 'optimistic'
          },
        },
        function(response, status) {
          //console.log("ENTRO_OK")
          if (status === "OK") {
            self.directionsDisplay.setDirections(response);
            self.directionsDisplay.setMap(self.$refs['gmap'+self.mapaNombre].$mapObject);

            self.displayLocationElevation(response.routes[0].overview_path,self.elevationService);

          } else {
            window.alert("Directions request failed due to " + status);
          }
        }
      );
    },
    computeTotalDistance(result) {
      //console.log(result)
      var google = window.google;
      var total = 0;
      var min = 0;
      var trafico = 0;
      var lineal = 0;
      var coordinate1 = "";
      var coordinate2 = "";
      var totalClean = 0

      var myroute = result.routes[0];
      for (var i = 0; i < myroute.legs.length; i++) {
        total += myroute.legs[i].distance.value;
        min += myroute.legs[i].duration.value;
        trafico += myroute.legs[i].duration_in_traffic.value;
      }

      total = total / 1000;
      min = (min * 1.25) / 60;
      trafico = (trafico * 1.25) / 60;

      coordinate1 = myroute.legs[0].start_location;
      coordinate2 = myroute.legs[myroute.legs.length - 1].end_location;
      lineal =
        google.maps.geometry.spherical.computeDistanceBetween(
          coordinate1,
          coordinate2
        ) / 1000;

      totalClean = this.measuringA =="imperial" ? (total * 0.621371) : (total)
      total =
        this.measuringA == "imperial"
          ? this.numberWithCommas(total * 0.621371) + " mi"
          : this.numberWithCommas(total) + " km";
      lineal =
        this.measuringA == "imperial"
          ? this.numberWithCommas(lineal * 0.621371) + " mi"
          : this.numberWithCommas(lineal) + " km";
      
      let res = this.ytdRoutes;
      if(this.originId){
        let origenes = [this.originId]
        if(origenes.length > 0){          
          res = res.filter((ruta) => origenes.includes(ruta.idOrigen) )
        }
      }
      if(this.destinationId){
        let destinos = [this.destinationId]
        if(destinos.length > 0){          
          res = res.filter((ruta) => destinos.includes(ruta.idDestino) )
        }
      }
      let transporte=['Auto']
      res = res.filter((ruta) => transporte.includes(ruta.transporte) )

      const sumVolume = res.map(a =>a.volTramo).reduce((accumulator, currentValue) => accumulator + currentValue,0,);
      const sumCost = res.map(a =>a.costoTramo*a.volTramo).reduce((accumulator, currentValue) => accumulator + currentValue,0,);
      
      this.mediciones= {
        title: this.lblMediciones,
        streetDistance: total,
        linealDistance: lineal,
        averageTime: min,
        trafficTime: trafico,
        speed: this.numberWithCommas(totalClean / (trafico/60))+" km/hr",
        costo: this.numberWithCommas(sumVolume>0?sumCost / sumVolume:0) +' $Tn',
        costoTnKm: this.numberWithCommas((sumVolume>0? sumCost / sumVolume:0) / totalClean) +' $TnKm',
      }
      //console.log(res, this.originId, this.destinationId)
    },    
    isEmpty(obj) {
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
      }
      return true;
    },
    setLocation(position, data, line = false, detail= {}) {

      let inBetween = position
      this.infoLine = false
      if(line){
        inBetween = google.maps.geometry.spherical.interpolate(position[0], position[1], 0.5)
        this.infoLine = true
        this.infoDetail = detail
        //console.log(detail)
      }

      this.infoWindowPos = inBetween
      this.infoWindowId = data.id
      this.infoWinOpen= true
      this.infoWinTitle = line? '': data.title
    },
    saveLocation(position, id) {      
      this.infoWinOpen= false     
      
      if(this.locationType == 'origen'){
        this.originRule = position
        this.originId= id
      }
      else{
        this.destinationRule = position
        this.destinationId = id
      }      
      if (this.isEmpty(this.originRule) || this.isEmpty(this.destinationRule)) {
        return;
      }
      this.calculateAndDisplayRoute(this.time);
      this.bRegla = 1;
      this.titleBtnRegla = this.lblHideRuler;
    },    
    trim(str) {
      return str.replace(/^\s+|\s+$/g, "");
    },
    updateSpider(){
      
      if(this.tipoSpider=='abasto'){
        this.spiders=[]
        this.genericos = []
        this.abastoNOM.forEach(tramo =>{
          
          setTimeout(() => {
            this.spiders.push({
                info:{ tramo },
                path:[
                  {lat: tramo.lat_origen*1, lng: tramo.lng_origen*1 },
                  {lat: tramo.lat_destino*1, lng: tramo.lng_destino*1 }],
                  pathOptions:{
                    strokeColor: '#f00',
                    strokeWeight: 8,
                    editable: false,
                    strokeOpacity: 0,
                    icons: [{
                      icon: {
                        path: 'M 0,-1 0,1',
                        strokeOpacity: 1,
                        scale: 2
                      },
                      offset: '0',
                      repeat: '10px'
                    }, {
                      icon: {
                        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                        offset: '100%',
                        fillColor: '#f00',
                        fillOpacity: 1,
                        strokeOpacity: 1,
                        scale: 2
                      }
                    }],
                  }
              })

            if(!tramo.nombre_destino.startsWith("MX-PTA.")){
              this.genericos.push({
                id: tramo.idDestino,
                extra:{
                  idsap: ''
                },
                nombre: tramo.nombre_destino,
                ubicacion:{
                  lat: tramo.lat_destino*1,
                  lng: tramo.lng_destino*1
                },
              })
            }
          }, "500")
            
        })
        this.generaPoligonos()
      }
      else if(this.tipoSpider=='entregas'){
        this.spiders=[]
        this.genericos = []
        this.entregasNOM.forEach(tramo =>{
          
        setTimeout(() => {
          this.spiders.push({
              path:[
                {lat: tramo.lat_origen*1, lng: tramo.lng_origen*1 },
                {lat: tramo.lat_destino*1, lng: tramo.lng_destino*1 }],
                pathOptions:{
                  strokeColor: '#00f',
                  strokeWeight: 8,
                  editable: false,
                  strokeOpacity: 0,
                  icons: [{
                    icon: {
                      path: 'M 0,-1 0,1',
                      strokeOpacity: 1,
                      scale: 2
                    },
                    offset: '0',
                    repeat: '10px'
                  }, {
                    icon: {
                      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                      offset: '100%',
                      fillColor: '#00f',
                      fillOpacity: 1,
                      strokeOpacity: 1,
                      scale: 2
                    }
                  }],
                }
            })

          if(!tramo.nombre_destino.startsWith("MX-PTA.")){
            this.genericos.push({
              id: tramo.idDestino,
              extra:{
                idsap: ''
              },
              nombre: tramo.nombre_destino,
              ubicacion:{
                lat: tramo.lat_destino*1,
                lng: tramo.lng_destino*1
              },
            })
          }
        }, "500")

            
        })
        this.generaPoligonos()
      }
      else if(this.tipoSpider=='sin'){
        this.spiders=[]
        this.genericos=[]
      }
            
    },
    generaPoligonos(destinos){

      this.poligonos =[]
      let poligonosIds={} //id de poligonos unico
      let destinoActual;
      let poligonoActual;
      let tempPoligonos=[];

      /* for(let i=0; i< destinos.length; i++){
        
        destinoActual = destinos[i]

        for(let w=0; w < this.poligonosNOM.length;w++){
          poligonoActual= this.poligonosNOM[w]

          let tempArr = poligonoActual.path.replace(/['"()]+/g, "").split(",")          
          let tempPath=[];

          for (var y = 0; y < tempArr.length; y += 2) {
            tempPath.push(
              new google.maps.LatLng(
                this.trim(tempArr[y]),
                this.trim(tempArr[y + 1])
              )
            );
          }

          let actualPolygon = new google.maps.Polygon({
            paths: tempPath,
          });
          let coordinate = new google.maps.LatLng(
            destinoActual.lat_destino*1,
            destinoActual.lng_destino*1
          );
        
          let estaDentroPoligono = google.maps.geometry.poly.containsLocation(
            coordinate,
            actualPolygon
          );

          if(estaDentroPoligono && !poligonosIds[poligonoActual.nombre]){

            let polyOptions = {
              strokeColor: '#000',
              strokeOpacity: 1.0,
              strokeWeight: 1,
              name: poligonoActual.nombre,
              fillColor: poligonoActual.color,
              fillOpacity: 0.2,
              editable: false,
              zIndex: 1000,
              color: poligonoActual.color,
              draggable: false
            }

            tempPoligonos.push({
              options: polyOptions,
              path: tempPath,
              editable: false,
              draggable: false,
            });

            poligonosIds[poligonoActual.nombre]=1

            break;
          }

        }
      } */

      let poligonosTemp = this.poligonosNOM.filter((poligono) => poligono.tipo != '')

      poligonosTemp.forEach(poligonoActual =>{
          
        let tempArr = poligonoActual.path.replace(/['"()]+/g, "").split(",")          
        let tempPath=[];

        for (var y = 0; y < tempArr.length; y += 2) {
          tempPath.push(
            new google.maps.LatLng(
              this.trim(tempArr[y]),
              this.trim(tempArr[y + 1])
            )
          );
        }

          let polyOptions = {
            strokeColor: '#000',
            strokeOpacity: 1.0,
            strokeWeight: 1,
            name: poligonoActual.nombre,
            fillColor: poligonoActual.color,
            fillOpacity: 0.2,
            editable: false,
            zIndex: 1000,
            color: poligonoActual.color,
            draggable: false
          }

          tempPoligonos.push({
            options: polyOptions,
            path: tempPath,
            editable: false,
            draggable: false,
          });

      })
        
      this.poligonos = [... tempPoligonos]
      
    },
    cambioIdle(){
      const google = window.google;
      this.center = this.$refs['gmap'+this.mapaNombre].$mapObject.getCenter();
      let aZoom = this.$refs['gmap'+this.mapaNombre].$mapObject.getZoom();
      this.actualZoom = aZoom
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight - this.ajusteVentana;
    },
    clearMarkersSearchBox() {
      this.markersSearchBox.forEach((marker) => {
        marker.setMap(null);
      });
      this.markersSearchBox = [];
      document.getElementById("pac-input"+this.mapaNombre).value = "";
      const event = new Event("input");
      document.getElementById("pac-input"+this.mapaNombre).dispatchEvent(event);
    },
    plotElevation({ results }) {           
      let tempLabels= []
      let tempData= []
      let tempAcumSlope=0
      //console.log(results)
      for (let i = 0; i < results.length; i++) {
        tempLabels.push(i)
        tempData.push(Math.round(results[i].elevation*100)/100)
        if(i > 0 && i < results.length-1 && results.length>2){
          if(results[i+1].elevation > results[i].elevation){
            tempAcumSlope= tempAcumSlope + (results[i+1].elevation - results[i].elevation)
          }
        }
      }
      this.resultElevation= [... results]
      this.chartData.labels = [...tempLabels]
      this.chartData.datasets[0].data = [... tempData]
      this.refrescaChart+=1;
      this.acumSlope= tempAcumSlope
    }
  }
}
</script>
<style scoped>
  .shadowLabel {    
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;    
  }
  .map-control {
    margin-top: 5px;
  }
  .map-control-complex {
    margin-top: 8px;
    margin-left: -9px;    
    min-height: 35px;
    min-width: 35px;
    align-content: center;
  }
  .rulerDetailContainer {
    background-color: #fff;
    cursor: pointer;
    position: relative;
    bottom:10px;
    right: 10px;
    width: 40px;
    height:125px;
    border-radius: 5px;
  }
  .rulerDetail{    
    position: absolute;
    top: 0;
    right: 45px;
    width: 600px;
    height: 125px;
    font-size: 0.9rem;
    align-content: center;
    text-align:center;
    display:grid;
    gap:0.5rem;
    border-radius: 5px;
    background-color: #fff;
    padding: 0.5rem;
  }  
  .tramo {
    background-color: #eee;
    color: hsl(var("--clr-light"));
    font-weight: 400;
  }

  table {
    text-align: left;
    position: relative;
    border-collapse: collapse;
    min-width: 100%;
  }
  th, td {
    padding: 0.25rem;
    text-align: center;
    white-space: nowrap;
   color: #000;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid #fff;
  }
  tr th {
    background: white;
    color: black;
  }
  tr td.detail {
    background: #D1E4FF;
    font-size: .9rem;
  }
  tr td.detailGreen {
    background: #B2E6EF;
    font-size: .9rem;
  }
  tr th.detailGrey {
    background: #ddd;
    font-size: .9rem;
  }
  tr th.detailLightGrey {
    background: #eee;
    font-size: .9rem;
  }

  th {
    background: white;
    position: sticky;
    top: 0; /* Don't forget this, required for the stickiness */
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  }

  .totalDetail{
    background-color: #333;
    color: #fff;
    font-size: .9rem;
    font-weight: 400;
  }
  .titleInfoLine{
    font-weight: 400;
    font-size: .9rem;
  }
  .elevationChart{
    background-color: #fff;
    margin-bottom: 1rem;
    margin-right: 0.5rem;
    padding: 0.5rem;
    border-radius: 5px;
  }
</style>
